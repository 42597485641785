// Hack for iPhone to prevent zooming on input focus.
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 670px) {
    div.ui > select,
    div.ui > textarea,
    div.ui > input,
    .field > textarea {
        font-size: 16px !important;
        padding: 0.5rem !important;
    }
}

.ui {
    &.table {
        &.compact-header,
        &.compact {
            th {
                padding-top: 0.5em !important;
                padding-bottom: 0.5em !important;
            }
        }
    }

    &.selection.dropdown {
        min-width: 6em;
    }

    &.form.small {
        .selection.dropdown {
            max-height: 2.2em !important;
            min-height: 2.2em !important;
            padding-top: 0.5em;
            padding-bottom: 0.15em;

            & > .delete.icon,
            & > .dropdown.icon,
            & > .search.icon {
                cursor: pointer;
                padding: 0.6em 0.8em;
            }
        }

        .field, .fields {
            margin-bottom: 0.5em;
        }

        .input {
            input {
                padding: 0.4em 0.8em !important;
            }

            .label {
                padding: 0.5em 0.8em !important;
            }
        }

        .button {
            padding: 0.6em 0.8em !important;
        }
    }

    &.checkbox22 {
        line-height: 1.3em;
        /* vertical-align: baseline; */
        min-height: 1em;
        font-size: 1em;
        min-width: 1.3em;

        input[type=checkbox],
        input[type=radio], {
            width: 1.3em;
            height: 1.3em;
        }

        label:after,
        label:before {
            width: 1.3em;
            height: 1.3em;
            font-size: 1em;
        }

        &.radio {
            label:after,
            label:before {
                width: 1.2em;
                height: 1.2em;
                font-size: 1em;
            }
        }
    }

}

//.field.readonly {
//    .ui.input input {
//        background-color: #dedede !important;
//    }
//}


*:focus {
    //outline: #ffb43388 solid 2px !important;
}

// Fixes invisible checkbox when it is on white background and in OFF position.

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
    background: #ddd !important;
}

// Fixes very pale Card header on bad monitors

.ui.attached.block.header {
    background: #ededed !important;
}

.pw-navbar-menu > .menu {
    height: 43px !important;
}

#_hj_feedback_container {
    @media print {
        display: none !important;
    }

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}


