@import 'MenuBar/menubar.css';
@import 'radix/button/button.css';
@import 'radix/calendar/calendar.css';
@import 'app-layout/Navbar/navbar.css';

@layer base {
    ::selection {
        background: var(--ez-main-background-color-accent);
    }

    a {
        @apply text-link;
        &:hover {
            @apply text-link-hover;
        }
    }
}

@layer utilities {
    @media (hover: hover) {
        .focusable {
            /*@apply transition-colors;*/
        }
    }

    .input-invalid-error {
        @apply border-red bg-red-50;
    }

    .focusable {
        @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1;
        @apply disabled:pointer-events-none disabled:opacity-60;
        @apply placeholder:text-gray-400 data-[placeholder]:text-gray-400;
        @apply aria-[invalid=true]:border-red aria-[invalid=true]:bg-red-50;
    }
}

.lucide {
    width: 1em;
    height: 1em;
    stroke-width: 2.5px;
}
