@import './variables.scss';

.rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
}

.rbc-timeslot-group {
    flex: 1;
    box-sizing: content-box;
    display: flex;
    flex-flow: column nowrap;
    min-height: var(--calendar-cell-min-height, 60px) !important;
    max-height: 100px;

    &:first-child {
        border-top: 1px solid transparent;
    }
    border-bottom: 1px solid transparent;

}

.rbc-day-slot {
    position: relative;

    .rbc-events-container {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        margin-right: 4px;
        margin-left: 4px;
        top: 0;
    }

    .rbc-event {
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        box-shadow: 0 0 8px 4px rgb(180 180 180 / 40%);
        display: flex;
        max-height: 100%;
        min-height: 12px;
        flex-flow: column wrap;
        align-items: flex-start;
        overflow: hidden;
        position: absolute;
    }

    .rbc-event-label {
        flex: none;
        padding-right: 5px;
        width: auto;
    }

    .rbc-event-content {
        width: 100%;
        flex: 1 1 0;
        word-wrap: break-word;
        line-height: 1;
        height: 100%;
        min-height: 1em;
    }
}

.rbc-time-gutter,
.rbc-time-header-gutter {
    position: sticky;
    left: 0;
    z-index: 10;
    margin-right: -1px;
    flex: none;
    width: 64px;
    padding: 8px 8px;
    background-color: var(--ez-panel-body-background-color);
    //border-right: 2px solid $cell-border;
}

.rbc-time-view-resources {
    .rbc-time-header {
        overflow: hidden;
    }

    .rbc-time-header-content {
        min-width: auto;
        flex: 1 0 0;
    }

    .rbc-time-header-cell-single-day {
        //display: none;
    }

    .rbc-day-slot {
        min-width: $day-column-min-width;
    }

    //.rbc-header {
    //    color: #347199;
    //    text-decoration: none;
    //}

    .rbc-header,
    .rbc-day-bg {
        width: $day-column-min-width;
        flex: 1 1 0;
    }
}

.rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px;
}

.rbc-time-slot {
    flex: 1 0 0;
}
