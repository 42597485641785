@import '@ez/components/css/ez-base-styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "datepicker";
@import "hacks";
@import "print";

:root {
    --rbc-gap-between-day-columns: 4px;
}

main#content {
    overflow-x: auto;
}
