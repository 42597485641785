@import './variables.scss';
@import './time-column.scss';


.rbc-time-header {
    display: flex;
    flex: 0 0 auto; // should not shrink below height
    flex-direction: row;

    z-index: 4;
    border-right: none;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 1%);

    > .rbc-row:first-child {
        //border-bottom: 1px solid $cell-border;
    }

    // .rbc-gutter-cell {
    //   flex: none;
    // }

    // > .rbc-gutter-cell + * {
    //   width: 100%;
    // }

    &-gutter {
        //background-color: var(--ez-panel-body-background-color);
        padding-bottom: 8px;
        padding-top: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }


    &-content {
        flex: 1;
        display: flex;
        min-width: 0;
        flex-direction: column;
        gap: 4px;
        //padding-bottom: 8px;
        align-items: stretch;
        border: 1px solid transparent;
        padding: 0 $gap-between-day-columns;
        padding-bottom: 8px;

        //padding-right: $gap-between-day-columns;
        //border-left: 1px solid $cell-border;


        > .rbc-time-header-date-row {
            //border-bottom: 1px solid $cell-border;
            flex-shrink: 0;
            min-width: $day-column-min-width;
        }

        > .rbc-time-header-resource-row {
            display: flex;
            flex-direction: row;

            > .rbc-time-header-resource-cell {
                width: $day-column-min-width;
                flex: 1 1 0;
                // min-width: 0;
                //padding: 8px;

                > .rbc-time-header-resource-cell-content {
                    //height: 44px;
                    display: grid;
                    justify-content: start;
                    align-content: center;
                    padding: 4px 8px;
                    border-radius: 8px;
                    background-color: #ffffff;
                    font-weight: bolder;
                    font-size: 0.8em;
                    border: 1px solid #a8b2ba;
                }

            }

        }
    }
}
