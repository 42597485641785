$react-dates-width-input: 130px;

.DateInput {
    line-height: 1.2142em;
    padding: 0 8px;
}

.SingleDatePickerInput {
    border-radius: .28571429rem;
}


//TimePiker styles

//Fixes for SemanticUI and rc-time-picker style conflicts
.rc-time-picker-input {
    padding: 4px 8px !important;
    line-height: 1.5 !important;
    font-size: 12px !important;
}

$navigation-button-color: #4287b7;

.pw-datepicker-popper {
    //z-index: 10000 ;
    .react-datepicker {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5)
    }


    &.pw-react-datepicker__arrow--align-center {
        .react-datepicker__triangle {
            left: 50%;
        }
    }
}

.pw-datepicker {
    .react-datepicker__navigation--previous {
        left: 10px;
        border-right-color: $navigation-button-color;
    }

    .react-datepicker__navigation--next {
        right: 10px;
        border-left-color: $navigation-button-color;
    }

    .react-datepicker__day--highlighted-custom {
        border-radius: 0.3rem;
        border: 1px solid #216ba5;
        margin: 0;
    }

    .react-datepicker__today-button {
        line-height: 2rem;
        border-radius: 0 0 0.3rem 0.3rem;
        text-decoration: underline;
    }

}


// HACKS:
.ui.buttons {
    .react-datepicker-wrapper {
        .button {
            border-radius: 0 !important;
            margin: 0 !important;
            margin-left: -1px !important;

            &.labeled {
                padding-left: 3em !important;
                padding-right: 1em !important;
            }
        }
    }
}


$datepicker__selected-color: #838d93;
$datepicker__selected-background-color: #e6ffea;
$datepicker__background-color: white;
$datepicker__muted-color: #cdcdcd;
$datepicker__border-radius: 2px;

.pw-datepicker {
    .react-datepicker__day {
        user-select: none;

        &, &-name {
            margin: 0.166rem;
        }

        border: 1px solid #d9d8d8;

        &--outside-month {
            //visibility: hidden;
            color: #828282;
            background-color: transparent;
            border: none;
        }

        &--highlighted-range {
            background-color: $datepicker__selected-background-color;
        }

        &--highlighted-custom:not(&--outside-month) {
            border: 1px solid #ff6a6a;
            background-color: #fdd4d4;
            border-radius: 6px;
            //margin: inherit;
        }

        &--highlighted-range {
            //border: 1px solid $datepicker__selected-color;
            background-color: #e2e2e2;
            border-radius: $datepicker__border-radius;
            //margin: inherit;
        }

        &--selected,
        &--in-selecting-range,
        &--in-range {
            border-radius: $datepicker__border-radius;
            background-color: $datepicker__selected-color;
            color: #fff;

            &:hover {
                border-color: $datepicker__selected-color;
                background-color: $datepicker__selected-color;
            }
        }

        &--disabled {
            color: $datepicker__muted-color;
            pointer-events: none;

            &:hover {
                cursor: default;
                background-color: transparent;
            }
        }


    }
}


