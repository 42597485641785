@import './variables.scss';
@import './time-column.scss';

.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: $time-selection-bg-color;
    color: $time-selection-color;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}

.rbc-slot-selecting {
    cursor: move;
}

.rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    //border: 1px solid $calendar-border;
    //border-radius: 4px;
    min-height: 0;
    //background-color: white;

    .rbc-allday-cell {
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .rbc-allday-cell + .rbc-allday-cell {
        border-left: 1px solid $cell-border;
    }

    .rbc-allday-events {
        position: relative;
        z-index: 4;
    }

    .rbc-row {
        box-sizing: border-box;
        min-height: 20px;
    }

}

.rbc-time-gutter {
    //&-content {
    //    font-size: smaller;
    //    text-align: center;
    //    background-color: var(--ez-panel-body-background-color);
    //    border-radius: 8px;
    //    box-shadow: 0 0 20px rgba(20, 34, 84, 0.15)
    //}

    .rbc-timeslot-group:not(:last-child) {
        border-bottom-color: #CCCCCC;
    }
}

.rbc-time-content {
    //padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    position: relative;

    > * + * > * {
        //border-left: 1px solid $cell-border;
    }

    > .rbc-day-slot {
        width: 100%;
        user-select: none;
        -webkit-user-select: none;
    }

    .rbc-time-view-day-resources-group {
        display: flex;
        flex-direction: row;
        flex: 1;

        padding-top: 8px;

        .resource-day {
            //box-shadow: 0px 3px 3px 0px rgba(20, 34, 84, 0.15);
            //background-color: red;
            //border-radius: 8px;
            display: flex;
            flex-direction: row;
            flex: 1;
            border-left: 1px solid rgba(242, 242, 242, 0.25);
            border-right: 1px solid transparent;
            padding: 0 $gap-between-day-columns;
            //margin: 0 $gap-between-day-columns;

            overflow: hidden;
        }
        .rbc-day-slot {
            border-left: 1px solid $cell-border;
        }
        .rbc-timeslot-group {
            border-color: $cell-border;
        }

        .rbc-time-slot {
            &.workingHours {
                border-top: 1px solid rgb(246 246 246);
                background-color: rgb(255 255 255);
            }
            &.nonWorkingHours {
                background-color: rgb(236 236 236);
                border-top: 1px solid $cell-border-on-dark;
            }
        }



    }
    .rbc-time-gutter {
        background-color: var(--ez-panel-body-background-color);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        &-content {
            font-size: smaller;
            text-align: center;
            background-color: var(--ez-panel-body-background-color);
            border-radius: 8px;
            box-shadow: 0 0 20px rgba(20, 34, 84, 0.15);
        }
    }

}



.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;

    background-color: $current-time-color;
    pointer-events: none;
}


