$out-of-range-color: lighten(#333, 40%);
$out-of-range-bg-color: lighten(#333, 75%);


$calendar-border: #c9c9c9;
$cell-border: var(--rbc-grid-cell-border-color, rgba(230, 230, 230, 1.0));
$cell-border-on-dark: rgba(242, 242, 242, 0.5);

$border-color: #CCC;

//$segment-width: percentage(1 / 7);

$time-selection-color: white;
$time-selection-bg-color: rgba(0, 0, 0, .50);
$date-selection-bg-color: rgba(0, 0, 0, .10);


$event-bg: #e8e8e8;
$event-border: rgba(0, 0, 0, 0.06);
$event-outline: #3b99fc;
$event-color: #222222;
$event-border-radius: 2px;
$event-padding: 0;
$event-zindex: 4;

$btn-color: #373a3c;
$btn-bg: #fff;
$btn-border: #ccc;

$current-time-color: #74ad31;
$current-day-color: #79a142;

$rbc-css-prefix: rbc-i;

$today-highlight-bg: #eaf6ff;

$day-column-min-width: var(--rbc-day-column-min-width, 110px);
$gap-between-day-columns: var(--rbc-gap-between-day-columns, 2px);
