@layer components {
    .ez-menubar-item-base {
        @apply focusable;
        @apply h-full py-0 px-2 select-none rounded flex items-center whitespace-nowrap;
        @apply bg-menubar-item;
        @apply hover:bg-menubar-item-hover;
        @apply has-[a.active]:bg-menubar-item-active;
        &.active {
            @apply bg-menubar-item-active;
        }
    }

    .ez-menubar-section {
        @apply h-full flex flex-row items-center flex-1 gap-1;
        & > a {
            @apply ez-menubar-item-base;
        }
    }
}
