//PRINT support
@media print {

    .no-print {
        display: none !important;
    }

    div#root {
        aside {
            display: none !important;
        }

        header {
            display: none !important;
        }
    }

    main#main {
        .page-content {
            padding: 0;

            &-normal,
            &-wide {
                width: 100%;
            }
        }
    }
}


.page-print {
    @page {
        margin: 0;
        size: A4;
    }
    //padding: 10px;

    width: 100%;
    height: 100%;

    background: #ffffff;
    @media print {
        background-color: unset;
    }


    @media screen {
        flex-grow: 1;
        max-width: 24cm;
    }

    margin: 0 auto;

    -webkit-print-color-adjust: exact !important;
}
