/*
 *   base stylesheet for the calendar component
 *  https://github.com/gpbl/react-day-picker/blob/main/src/style.css
*/

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
    box-sizing: border-box;
    margin: 0;
    background: transparent;
    appearance: none;
    position: absolute !important;
    top: 0;
    padding: 0 !important;
    border: 0 !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
}

.rdp-months {
    display: flex;
}

.rdp-month {
    margin: 0 1em;
}

.rdp-month:first-child {
    margin-left: 0;
}

.rdp-month:last-child {
    margin-right: 0;
}

.rdp-table {
    margin: 0;
    border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
}

.rdp-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
}

.rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
}

.rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
}

.rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: var(--rdp-caption-font-size);
    font-weight: bold;
}

.rdp-nav {
    white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
    border-radius: 6px;
}

.rdp-dropdown_icon {
    margin: 0 0 0 5px;
}

.rdp-head {
    border: 0;
}

.rdp-head_cell {
    vertical-align: middle;
    font-size: 0.75em;
    font-weight: 700;
    text-align: center;
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-transform: uppercase;
}

.rdp-tbody {
    border: 0;
}

.rdp-tfoot {
    margin: 0.5em;
}

@layer components {
    .rdp-button {
        border: 2px solid transparent;
    }

    /*.rdp-button[disabled]:not(.rdp-day_selected) {*/
    /*    opacity: 0.25;*/
    /*}*/

    .rdp-button:not([disabled]) {
        cursor: pointer;
        @apply focusable;
        @apply hover:bg-accent hover:text-accent-foreground;
        /*&:not(.rdp-day_selected) {*/
        /*    @apply hover:bg-accent hover:text-accent-foreground;*/
        /*}*/
    }

    /*.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {*/
    /*    !*background-color: var(--rdp-background-color);*!*/
    /*    @apply bg-accent;*/
    /*}*/

    .rdp-cell {
        @apply h-9 w-9 text-center text-sm p-0 relative focus-within:relative focus-within:z-20;
    }

    .rdp-row {
        /*height: 100%;*/
        @apply flex w-full mt-2;
    }

    .rdp-weeknumber {
        font-size: 0.75em;
    }

    .rdp-weeknumber,
    .rdp-day {
        @apply hover:bg-accent hover:text-accent-foreground rounded;
        @apply h-9 w-9 p-0 font-normal aria-selected:opacity-100;
        @apply flex justify-center items-center overflow-hidden;
    }

    .rdp-day_today {
        @apply border bg-primary-25  border-primary-25 rounded-full;

        &:not(.rdp-day_outside) {
            @apply font-bold;
        }
    }

    .rdp-day_selected {
        @apply bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground;
    }

    .rdp-day_disabled {
        @apply text-secondary opacity-50;
    }

    .rdp-day_outside {
        @apply opacity-50 aria-selected:bg-secondary aria-selected:opacity-50 aria-selected:text-secondary;
    }

    .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .rdp-day_range_end.rdp-day_range_start {
        border-radius: 100%;
    }

    .rdp-day_range_middle {
        border-radius: 0;
    }
}
