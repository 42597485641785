.water-test-form {

    .ui.form textarea {
        background-color: ivory;
    }

    .water-test-input-table {
        div.ui.input {
            .label {
                border: 1px solid rgba(34, 36, 38, .35);
            }
            & > input {

                font-size: 1.2rem !important;
                padding: 0.45rem 1rem !important;
                border: 1px solid rgba(34, 36, 38, .35);
                @media (max-width: 1023px) {
                    max-width: 140px;
                }
            }
        }

        label {
            font-size: 0.9rem;
        }

        &.ui.table.basic {
            border: none !important;

            th {
                font-size: 0.9em;
                border: none !important;

                &.target {
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }

            tr {
                border: none !important;

                td {
                    padding-bottom: .1em;
                    border: none !important;

                    &.target {
                        font-size: 0.9em;
                    }

                    &.previous {
                        color: #9c27b0;
                    }
                }
            }

        }
    }
}
