@layer components {
    .ez-navbar-root {
        @apply flex items-center flex-row gap-1 py-1 px-2 print:hidden;
        background-color: var(--ez-navbar-background-color);
        color: var(--ez-navbar-menu-item-text-color, hsl(0, 0%, 100%));
        height: calc(var(--topBar, 46px)) !important;
        left: var(--sideBar, 180px);
        position: fixed;
        z-index: 897;
        right: 0;
        top: 0;
        border-bottom: 2px solid var(--ez-navbar-separator-color, #e0e0e0);
        padding-right: var(--removed-body-scroll-bar-size);
    }

    .ez-navbar-menu-section {
        @apply h-full flex flex-row items-center flex-1 gap-1;
    }

    .ez-navbar-menu-label {
        @apply h-full py-2 px-4 outline-none select-none;
        color: var(--ez-navbar-menu-item-text-color, hsl(0, 0%, 100%));
    }

    .ez-navbar-menu-item {
        @apply focusable h-full py-2 px-3 outline-none select-none rounded;
        @apply inline-flex flex-row flex-nowrap items-center;
        color: var(--ez-navbar-menu-item-text-color, hsl(0, 0%, 100%));
        background-color: var(--ez-navbar-menu-item-background-color, hsl(234, 22%, 33%));
        flex-wrap: nowrap;

        &.active {
            background-color: var(--ez-navbar-menu-item-active-background-color, hsl(0 0 0 / 0.3));
        }

        &:hover {
            background-color: var(--ez-navbar-menu-item-background-color-hover, hsl(0 0 0 / 0.25));
        }
    }

    .ez-navbar-dropdown-menu {
        @apply ez-navbar-menu-item;

        &[data-state='open'] {
            background-color: var(--ez-navbar-menu-item-hover-background-color, hsl(0 0 0 / 0.25));
        }

        &-content {
            min-width: var(--radix-menubar-trigger-width, 8rem);
        }
    }
}
