.stats-table {
    th {
        &.rotate-header {
            height: 170px;
            width: 40px;
            white-space: nowrap;
            vertical-align: middle;
            position: relative;

            & > div {
                position: absolute;
                transform: translate(0px, 60px) rotate(270deg);
                width: 15px;

                & > span {
                    //padding: 5px 10px;
                }
            }
        }
    }

    th,
    tr > td {
        &:nth-child(10n + 1) {
            border-right: 1px solid #ccc;
        }
        text-overflow: unset !important;
    }

    .pw-stats-date-cell {
        width: 120px;
    }
}
