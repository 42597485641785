@layer components {
    .ez-btn-base {
        @apply h-button-base align-middle inline-flex items-center justify-center gap-1 whitespace-nowrap rounded;
        @apply px-4 text-base font-thin leading-none border border-solid border-gray-400;
        /*@apply hover:shadow-md;*/
        @apply focusable;
    }

    @media (hover: hover) {
        .ez-btn-base {
            @apply hover:shadow hover:bg-button-primary-hover hover:text-primary;
        }
    }
}
